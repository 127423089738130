/* eslint-disable no-shadow */
import axios from 'axios'
import {
  getByIdDocumentoCategoria,
  getByEmpleadorIdDocCat,
  postByEmpleadorIdFilter,
  postDocumentoCategoriaSaveAll,
  findAllDocCategorias,
  putDocumentoCategoriaUpdateAll,
  updateDocumentoCategoria,
  getByIsActivoCategoriaDocumentoEmpleador,
  postSaveCategoriaDocumentoEmpleador,
  putUpdateCategoriaDocumentoEmpleador,
} from '@/utils/paths'

const pathGetByIdDocumentoCategoria = getByIdDocumentoCategoria
const pathGetByEmpleadorId = getByEmpleadorIdDocCat
const pathPostByEmpleadorIdFilter = postByEmpleadorIdFilter
const pathGetAllDocCategorias = findAllDocCategorias
const pathPostDocumentoCategoriaSaveAll = postDocumentoCategoriaSaveAll
const pathPutDocumentoCategoriaUpdateAll = putDocumentoCategoriaUpdateAll
const pathUpdateDocumentoCategoria = updateDocumentoCategoria
const pathGetByIsActivoCategoriaDocumentacion = getByIsActivoCategoriaDocumentoEmpleador
const pathPostSaveCategoriaDocumentacion = postSaveCategoriaDocumentoEmpleador
const pathPutUpdateCategoriaDocumentacion = putUpdateCategoriaDocumentoEmpleador

export function documentoCategoriaResource() {
  function getByIdDocumentoCategoria(id) {
    return new Promise((resolve, reject) => {
      axios
        .get(pathGetByIdDocumentoCategoria + id)
        .then(results => {
          resolve(results.data)
        })
        .catch(e => {
          reject(e.response)
        })
    })
  }
  function getByEmpleadorIdDocumento(id) {
    return new Promise((resolve, reject) => {
      axios
        .get(pathGetByEmpleadorId + id)
        .then(results => {
          resolve(results.data)
        })
        .catch(e => {
          reject(e.response)
        })
    })
  }
  function postByEmpleadorIdFilter(filterDomicilioLaboralDto) {
    return new Promise((resolve, reject) => {
      axios
        .post(pathPostByEmpleadorIdFilter, filterDomicilioLaboralDto)
        .then(results => {
          resolve(results.data)
        })
        .catch(e => {
          reject(e.response)
        })
    })
  }
  function saveAllDocumentoCategoria(documentoCategoriaDto) {
    return new Promise((resolve, reject) => {
      axios
        .post(pathPostDocumentoCategoriaSaveAll, documentoCategoriaDto)
        .then(results => {
          resolve(results.data)
        })
        .catch(e => {
          reject(e.response)
        })
    })
  }
  function findAllDocCategorias() {
    return new Promise((resolve, reject) => {
      axios
        .get(
          pathGetAllDocCategorias,
        )
        .then(results => {
          resolve(results.data)
        })
        .catch(e => {
          reject(e.response)
        })
    })
  }
  function updateAllDocumentoCategoria(documentoCategoriaDto) {
    return new Promise((resolve, reject) => {
      axios
        .put(pathPutDocumentoCategoriaUpdateAll, documentoCategoriaDto)
        .then(results => {
          resolve(results.data)
        })
        .catch(e => {
          reject(e.response)
        })
    })
  }
  function updateDocumentoCategoria(documentoCategoriaDto) {
    return new Promise((resolve, reject) => {
      axios
        .put(pathUpdateDocumentoCategoria, documentoCategoriaDto)
        .then(results => {
          resolve(results.data)
        })
        .catch(e => {
          reject(e.response)
        })
    })
  }
  function findAllByIsActivoCategoriaDocumentacion() {
    return new Promise((resolve, reject) => {
      axios
        .get(pathGetByIsActivoCategoriaDocumentacion)
        .then(results => {
          resolve(results.data)
        })
        .catch(e => {
          reject(e.response)
        })
    })
  }
  function saveCategoriaDocumentacion(categoriaDocumentacionDto) {
    return new Promise((resolve, reject) => {
      axios
        .post(pathPostSaveCategoriaDocumentacion, categoriaDocumentacionDto)
        .then(results => {
          resolve(results.data)
        })
        .catch(e => {
          reject(e.response)
        })
    })
  }
  function updateCategoriaDocumentacion(id, categoriaDocumentacionDto) {
    return new Promise((resolve, reject) => {
      axios
        .put(pathPutUpdateCategoriaDocumentacion + id, categoriaDocumentacionDto)
        .then(results => {
          resolve(results.data)
        })
        .catch(e => {
          reject(e.response)
        })
    })
  }
  return {
    getByEmpleadorIdDocumento,
    postByEmpleadorIdFilter,
    saveAllDocumentoCategoria,
    updateAllDocumentoCategoria,
    updateDocumentoCategoria,
    getByIdDocumentoCategoria,
    findAllDocCategorias,
    findAllByIsActivoCategoriaDocumentacion,
    saveCategoriaDocumentacion,
    updateCategoriaDocumentacion,
  }
}
