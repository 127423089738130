/* eslint-disable no-shadow */
import axios from 'axios'
import {
  getByIdDocumento,
  getByEmpleadorIdDoc,
  postByEmpleadorIdFilter,
  postDocumentoSaveAll,
  putDocumentoUpdateAll,
  updateDocumento,
  postDocumentoSave,
  postUploadFile,
  getEmpleadorDocumentoFile,
  deleteDocumentoEmpleador,
} from '@/utils/paths'

const pathGetByIdDocumento = getByIdDocumento
const pathGetByEmpleadorId = getByEmpleadorIdDoc
const pathPostByEmpleadorIdFilter = postByEmpleadorIdFilter
const pathPostDocumentoSaveAll = postDocumentoSaveAll
const pathPostDocumentoSave = postDocumentoSave
const pathUploadFile = postUploadFile
const pathPutDocumentoUpdateAll = putDocumentoUpdateAll
const pathUpdateDocumento = updateDocumento
const pathGetEmpleadorDocumentoFile = getEmpleadorDocumentoFile
const pathDeleteDocumentoEmpleador = deleteDocumentoEmpleador

export function documentoResource() {
  function getByIdDocumento(id) {
    return new Promise((resolve, reject) => {
      axios
        .get(pathGetByIdDocumento + id)
        .then(results => {
          resolve(results.data)
        })
        .catch(e => {
          reject(e.response)
        })
    })
  }
  function getByEmpleadorIdDocumento(id) {
    return new Promise((resolve, reject) => {
      axios
        .get(pathGetByEmpleadorId + id)
        .then(results => {
          resolve(results.data)
        })
        .catch(e => {
          reject(e.response)
        })
    })
  }
  function postByEmpleadorIdFilter(filterDomicilioLaboralDto) {
    return new Promise((resolve, reject) => {
      axios
        .post(pathPostByEmpleadorIdFilter, filterDomicilioLaboralDto)
        .then(results => {
          resolve(results.data)
        })
        .catch(e => {
          reject(e.response)
        })
    })
  }
  function saveAllDocumento(documentoDto) {
    return new Promise((resolve, reject) => {
      axios
        .post(pathPostDocumentoSaveAll, documentoDto)
        .then(results => {
          resolve(results.data)
        })
        .catch(e => {
          reject(e.response)
        })
    })
  }
  function saveDocumento(documentacionDto) {
    return new Promise((resolve, reject) => {
      axios
        .post(pathPostDocumentoSave, documentacionDto)
        .then(results => {
          resolve(results.data)
        })
        .catch(e => {
          reject(e.response)
        })
    })
  }
  function uploadFileAttachment(empleadorId, categoriaId, adjunto) {
    return new Promise((resolve, reject) => {
      const formData = new FormData() // eslint-disable-line
      formData.append('adjunto', adjunto)
      axios
        .post(`${pathUploadFile}/${empleadorId}/${categoriaId}`, formData, { headers: { 'Content-Type': 'multipart/form-data' } })
        .then(results => {
          resolve(results.data)
        })
        .catch(e => {
          reject(e.response)
        })
    })
  }
  function updateAllDocumento(documentoDto) {
    return new Promise((resolve, reject) => {
      axios
        .put(pathPutDocumentoUpdateAll, documentoDto)
        .then(results => {
          resolve(results.data)
        })
        .catch(e => {
          reject(e.response)
        })
    })
  }
  function updateDocumento(documentoDto) {
    return new Promise((resolve, reject) => {
      axios
        .put(pathUpdateDocumento, documentoDto)
        .then(results => {
          resolve(results.data)
        })
        .catch(e => {
          reject(e.response)
        })
    })
  }

  function getAdjuntoByte(empleadorDocumentoId) {
    return new Promise((resolve, reject) => {
      axios
        .get(
          pathGetEmpleadorDocumentoFile + empleadorDocumentoId, { responseType: 'blob' },
        )
        .then(results => {
          resolve(results.data)
        })
        .catch(e => {
          reject(e.response)
        })
    })
  }
  function deleteDocumento(documentoEmpleadorId) {
    return new Promise((resolve, reject) => {
      axios
        .delete(pathDeleteDocumentoEmpleador + documentoEmpleadorId)
        .then(results => {
          resolve(results.data)
        })
        .catch(e => {
          reject(e.response)
        })
    })
  }
  return {
    getByEmpleadorIdDocumento,
    postByEmpleadorIdFilter,
    saveAllDocumento,
    updateAllDocumento,
    updateDocumento,
    getByIdDocumento,
    saveDocumento,
    uploadFileAttachment,
    getAdjuntoByte,
    deleteDocumento,
  }
}
