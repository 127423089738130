/* eslint-disable no-shadow */
import axios from 'axios'
import {
  getAllLocalidad,
  getByCodigoLocalidad,
  getLocalidadId,
} from '@/utils/paths'

const pathGetAllLocalidad = getAllLocalidad
const pathGetByCodigoLocalidad = getByCodigoLocalidad
const pathGetLocalidadId = getLocalidadId

export function localidadResource() {
  function getAllLocalidad() {
    return new Promise((resolve, reject) => {
      axios
        .get(
          pathGetAllLocalidad,
        )
        .then(results => {
          resolve(results.data)
        })
        .catch(e => {
          reject(e.response)
        })
    })
  }

  function getByCodigoLocalidad(codigo) {
    return new Promise((resolve, reject) => {
      axios
        .get(
          pathGetByCodigoLocalidad + codigo,
        )
        .then(results => {
          resolve(results.data)
        })
        .catch(e => {
          reject(e.response)
        })
    })
  }
  function getLocalidadId(localidadId) {
    return new Promise((resolve, reject) => {
      axios
        .get(
          pathGetLocalidadId + localidadId,
        )
        .then(results => {
          resolve(results.data)
        })
        .catch(e => {
          reject(e.response)
        })
    })
  }
  return {
    getAllLocalidad,
    getByCodigoLocalidad,
    getLocalidadId,
  }
}
