/* eslint-disable no-plusplus */
/* eslint-disable import/prefer-default-export */
import { saveAs } from 'file-saver'

export function fileManagement() {
  function getBase64(file) {
    return new Promise((resolve, reject) => {
      const reader = new window.FileReader()
      reader.readAsDataURL(file)
      reader.onload = () => {
        resolve(reader.result)
      }
      reader.onerror = error => reject(error)
    })
  }
  function getAdjunto(contentDoc, nombreDoc, tipoDoc) {
    let blob = new Blob([contentDoc],{ type: tipoDoc }) // eslint-disable-line
    saveAs(blob, nombreDoc)
  }
  function getAdjuntoFromtBase64(contentDoc, nombreDoc, tipoDoc) {
    const encodedStr = this.base64ToArrayBuffer(contentDoc)
    let blob = new Blob([encodedStr],{ type: tipoDoc }) // eslint-disable-line
    saveAs(blob, nombreDoc)
  }

  function base64ToArrayBuffer(base64) {
    const binaryString = window.atob(base64)
    const binaryLen = binaryString.length
    const bytes = new Uint8Array(binaryLen)
    for (let i = 0; i < binaryLen; i++) {
      const ascii = binaryString.charCodeAt(i)
      bytes[i] = ascii
    }
    return bytes
  }
  function base64ToFile(dataurl, filename) {
    const arr = dataurl.split(',')
    const mime = arr[0].match(/:(.*?);/)[1]
    const bstr = atob(arr[1])
    let n = bstr.length
    const u8arr = new Uint8Array(n)

    while (n--) {
      u8arr[n] = bstr.charCodeAt(n)
    }

    return new File([u8arr], filename, { type: mime })
  }

  return {
    getBase64,
    base64ToFile,
    getAdjunto,
    getAdjuntoFromtBase64,
    base64ToArrayBuffer,
  }
}
