/* eslint-disable no-shadow */
import axios from 'axios'
import {
  findAll,
  getByIdDomicilioLaboral,
  postDomicilioLaboralSave,
  updateDomicilioLaboral,
  getByEmpleadorId,
  getByProvinciaId,
  postDomicilioLaboralSaveAll,
  postByEmpleadorIdFilter,
  putDomicilioLaboralUpdateAll,
  getDomicilioLaboralByEmpleadorId,
  getDomicilioLaboralById,
} from '@/utils/paths'

const pathGetAllDomicilioLaboral = findAll
const pathGetByIdDomicilioLaboral = getByIdDomicilioLaboral
const pathPostDomicilioLaboralSave = postDomicilioLaboralSave
const pathPostDomicilioLaboralSaveAll = postDomicilioLaboralSaveAll
const pathPutDomicilioLaboralUpdateAll = putDomicilioLaboralUpdateAll
const pathUpdateDomicilioLaboral = updateDomicilioLaboral
const pathGetByEmpleadorId = getByEmpleadorId
const pathGetByProvinciaId = getByProvinciaId
const pathPostByEmpleadorIdFilter = postByEmpleadorIdFilter
const pathGetDomicilioLaboralByEmpleadorId = getDomicilioLaboralByEmpleadorId
const pathGetDomicilioLaboralById = getDomicilioLaboralById

export function domicilioLaboralResource() {
  function getAllDomLaboral() {
    return new Promise((resolve, reject) => {
      axios
        .get(
          pathGetAllDomicilioLaboral,
        )
        .then(results => {
          resolve(results.data)
        })
        .catch(e => {
          reject(e.response)
        })
    })
  }
  function findByEmpleadorId(EmpleadorId) {
    return new Promise((resolve, reject) => {
      axios
        .get(pathGetDomicilioLaboralByEmpleadorId + EmpleadorId)
        .then(results => {
          resolve(results.data)
        })
        .catch(e => {
          reject(e.response)
        })
    })
  }
  function findById(id) {
    return new Promise((resolve, reject) => {
      axios
        .get(pathGetDomicilioLaboralById + id)
        .then(results => {
          resolve(results.data)
        })
        .catch(e => {
          reject(e.response)
        })
    })
  }
  function getByIdDomicilioLaboral(id) {
    return new Promise((resolve, reject) => {
      axios
        .get(pathGetByIdDomicilioLaboral + id)
        .then(results => {
          resolve(results.data)
        })
        .catch(e => {
          reject(e.response)
        })
    })
  }
  function getByEmpleadorIdDL(id) {
    return new Promise((resolve, reject) => {
      axios
        .get(pathGetByEmpleadorId + id)
        .then(results => {
          resolve(results.data)
        })
        .catch(e => {
          reject(e.response)
        })
    })
  }
  function postByEmpleadorIdFilter(filterDomicilioLaboralDto) {
    return new Promise((resolve, reject) => {
      axios
        .post(pathPostByEmpleadorIdFilter, filterDomicilioLaboralDto)
        .then(results => {
          resolve(results.data)
        })
        .catch(e => {
          reject(e.response)
        })
    })
  }
  function getByProvinciaIdDL(id) {
    return new Promise((resolve, reject) => {
      axios
        .get(pathGetByProvinciaId + id)
        .then(results => {
          resolve(results.data)
        })
        .catch(e => {
          reject(e.response)
        })
    })
  }
  function saveDomicilioLaboral(domicilioLaboralDto) {
    return new Promise((resolve, reject) => {
      axios
        .post(pathPostDomicilioLaboralSave, domicilioLaboralDto)
        .then(results => {
          resolve(results.data)
        })
        .catch(e => {
          reject(e.response)
        })
    })
  }

  function saveAllDomicilioLaboral(domicilioLaboralDto) {
    return new Promise((resolve, reject) => {
      axios
        .post(pathPostDomicilioLaboralSaveAll, domicilioLaboralDto)
        .then(results => {
          resolve(results.data)
        })
        .catch(e => {
          reject(e.response)
        })
    })
  }

  function updateAllDomicilioLaboral(domicilioLaboralDto) {
    return new Promise((resolve, reject) => {
      axios
        .put(pathPutDomicilioLaboralUpdateAll, domicilioLaboralDto)
        .then(results => {
          resolve(results.data)
        })
        .catch(e => {
          reject(e.response)
        })
    })
  }

  function updateDomicilioLaboral(domicilioLaboralDto) {
    return new Promise((resolve, reject) => {
      axios
        .put(pathUpdateDomicilioLaboral, domicilioLaboralDto)
        .then(results => {
          resolve(results.data)
        })
        .catch(e => {
          reject(e.response)
        })
    })
  }

  return {
    getAllDomLaboral,
    getByIdDomicilioLaboral,
    saveDomicilioLaboral,
    updateDomicilioLaboral,
    getByEmpleadorIdDL,
    getByProvinciaIdDL,
    saveAllDomicilioLaboral,
    postByEmpleadorIdFilter,
    updateAllDomicilioLaboral,
    findByEmpleadorId,
    findById,
  }
}
